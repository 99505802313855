/**
 * Two Columns Flex Layout (w/ Icon Row)
 * DBS Interactive
 */

 .two-columns {
	position: relative;

	&__left,
	&__right {
		position: relative;

		img {display: block;}
	}

	@include media($screen-sm-max, 'max') {
		&__left,
		&__right {

			&[class*="bg"] {
				margin-left: -$spacing;
				margin-right: -$spacing;
				padding: $spacing;
			}
		}

		&__left {margin-bottom: $spacing;}
	}

	@include media($screen-sm) {
		&__left,
		&__right {
			width: 50%;
		
			&.contain-img { img {@include object-fit(contain, center);} }
			&.cover {
				align-self: stretch;

				img {@include object-fit(cover, center);}
			}

			&:not(.has-img) {
				padding: $spacing;
			}

			&.overflow {
				height: 0;
				overflow: hidden;
				padding-bottom: 50%;
				position: static;

				img {
					@include object-fit(cover, center);
					top: 0;
					transform: none;
					width: 50%;
				}
			}
		}

		&__left.overflow img {left: 0;}
		&__right.overflow img {right: 0;}
	}

	@include media($screen-md) {
		&__left,
		&__right {

			&:not(.has-img) {
				padding: $spacing*2;
			}
		}

		&.two-thirds-left {
			.two-columns__left {width: 60%;}
			.two-columns__right {width: 40%;}
		}
	
		&.two-thirds-right {
			.two-columns__left {width: 40%;}
			.two-columns__right {width: 60%;}
		}
	}

	@include media($screen-lg) {
		&__left.has-icons {padding-right: $spacing*2;}
		&__right.has-icons {padding-left: $spacing*2;}
	}
}