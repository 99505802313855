/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */

.site-footer {
	overflow: hidden;
	clear: both;
	margin-top: auto; // Sticky footer

	.contain {padding: 0 $spacing;}

	// Resets
	ul { list-style: none; margin: 0; padding: 0; }

	// Default link styles
	a {
		text-decoration: none;
		
		&:not(.button) {
			&:hover { text-decoration: underline; }
		}
	}

	.site-footer__logo img {
		display: block;
		max-width: 10em;
		padding-bottom: 1em;
	}

	&__main {
		padding: 3rem 0;
	}

	&__business {
		ul, li {
			display: block;
		}
	}

	&__navigation {

		.footer-menu__item {
			a {
				color: $charcoal;
				display: inline-block;
				font-family: $font-brandon-grotesque;
				font-weight: 500;
				padding: .25rem 0;

				&:after {
					background: $charcoal;
					content: '';
					display: block;
					height: 1px;
					position: relative;
						top: 3px;
					transform: scaleX(0);
					transform-origin: 100% 0;
					transition: transform .25s ease-in-out;
				}
	
				&:hover,
				&:focus {
					text-decoration: none;

					&:after {
						transform: scaleX(1);
						transform-origin: 0 100%;
					}
				}
			}

			&.toplevel {
				margin-bottom: 1rem;
			}
		}

		li { display: block; }
	}

	.social-media {
		margin: 1em 0;

		&__link {display: inline-block;}

		a {display: block; width: 2em;}

		svg path {
			fill: $charcoal;
			transition: .25s;
		}

		a:hover,
		a:focus {
			svg path {fill: $orange;}
		}
	}

	&__credits {
		width: 100%;

		.contain {
			border-top: 2px solid $gray;
			padding: $spacing;
			width: 100%;
		}

		.legal-footer {
			display: inline-block;

			ul, li {display: inline-block;}

			.legal-menu__item {
				border-left: 1px solid $white;
				margin-left: .5rem;
				padding-left: .75rem;
			}
		}
	}

	@include media($screen-sm-max, 'max') {

		&__contact {
			border-bottom: 2px solid $gray;
			border-top: 2px solid $gray;
			margin: #{$spacing} 0 #{$spacing*2};
			padding: 3rem 0;
		}

		&__navigation {
			.footer-menu__submenu {display: none;}

			.footer-menu__item {
				
				&--parent {
					position: relative;

					&.open {
						.footer-menu__submenu {display: block;}
					}
				}
			}

			.submenu-toggle {
				top: -.75rem;

				svg path {fill: $white;}
			}
		}
	}

	@include media($screen-sm) {
		&__main {
			padding: 6rem 0;
		}

		&__navigation {
			width: 30%;

			> ul {
				margin: $spacing 0;
			}
		}

		&__contact {
			border-left: 2px solid $gray;
			border-right: 2px solid $gray;
			margin: 0 $spacing;
			padding: 0 $spacing;
			width: 40%;
		}

		&__meta {
			width: 30%;
		}
	}
}
