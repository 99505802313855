.contributors-slider {

	&__buttons {
		display: flex;
			justify-content: space-evenly;
		margin-top: 3rem;

		.flickity-viewport {
			overflow: visible;
			width: 100%;
		}
	}

	&__button {
		background: none;
		border: 4px solid $grayLighter;
		border-radius: 50%;
		flex: 1;
		height: 5rem;
		margin: 0 .5rem;
		max-width: 5rem;
		padding: 0;
		transition: .25s;
		width: 5rem;

		&.is-selected {
			border-color: $peach;
			transform: scale(1.25);
		}
	}

	&__image {
		border-radius: 50%;
		overflow: hidden;
		padding-bottom: 100%;
		position: relative;

		img { @include object-fit(cover, center); }
	}

	&__text {
		width: 100%;

		p {
			margin: 0 auto $spacing;
			max-width: 40rem;
		}

		a {
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	.flickity-enabled,
	.contributors-slider__button {box-shadow: none !important;} // override global focus state

	.flickity-viewport {transition: .25s;}

	@include media($screen-sm) {

		&__button {
			height: 7rem;
			max-width: 7rem;
			width: 7rem;
		}
	}
}