/**
 * DBS Slate Front Page Stylesheet
 * DBS>Interactive
 *
 * This stylesheet will only be loaded on the front page.
 */
/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

/**
 * Global Mixins
 * DBS>Interactive
 */
/**
 * Helpful mixin for font size scaling.
 *
 * Decimal values should not be used here!
 * The results will not be what you'd expect.
 *
 * @usage
 * 		@include font-scale(-1) - smaller
 * 		@include font-scale(0) = 1em (.8em in mobile)
 * 		@include font-scale(1) - larger
 * 			etc...
 */
/**
 * Fixes clear bug in certain situations
 *
 * @group layout
 * @link http://fuseinteractive.ca/blog/understanding-humble-clearfix#.VJ853sAEo Understanding the humble clearfix
 * @example scss - Usage
 *   @extend clearFix; // That's It!
 */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

/**
 * An easy way to truncate text with an ellipsis. Requires the element to be block or inline-block.
 *
 * @group Typography
 * @link http://web-design-weekly.com/2013/05/12/handy-sass-mixins/ Handy Sass Mixins
 * @example scss - Usage
 *   .text-truncate {
 *	  @extend text-truncate;
 *   }
 */
/**
 * Hide On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include hideOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include hideOn($baby, 'max');
 */
/**
 * Show On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include showOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include showOn($baby, 'max');
 */
/**
 * Size mixin - Sets width and height.
 *
 * When only one argument is specified, both the height and width are set to the same value.
 *
 * @param width - required
 * @param height
 */
/**
 * REFLEX ISH GRID
 */
/**
 * Exponent function
 *
 * NOTE: This does NOT work for decimal values
 *
 *   @see: https://css-tricks.com/snippets/sass/power-function/
 */
/**
 * Object Fit
 *
 * If a browser supports object-fit it will use it with the params
 * given. Otherwise it will fall back on a more basic image centering method.
 *
 * @param size - required (cover, contain, auto, etc.)
 * @param position - required (center, left, right, top, bottom right, etc.)
 */
/**
 * Aspect Ratio
 *
 * Used to create a padding box that an image/video can be placed in.
 *
 * Example @include aspect-ratio(16, 9);
 */
/**
 * Button mixin
 * @param $color1 - foreground color
 * @param $color2 - background color
 */
/**
 * Slate Layout Mixins
 * DBS>Interactive
 */
/**
 * Default responsive padding mixin for layout content
 */
/**
 * Default responsive margin mixin for layout content
 */
/**
 * Used for mimicking the vertical space provided by the default-margin mixin, but
 * with padding for colored background layouts.
 */
/**
 * Mixin to keep space (either margin or padding) between cells consistent
 *
 * It takes 2 arguments:
 * 		@param $space_property = { 'margin'|'padding' }
 * 		@param $reverse = { true|false }
 */
/**
 * Responsive layout mixin to constrain a width to containSize.
 */
/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
/**
 * Fonts
 */
/**
 * Type style definitions
 */
.categories-cta__image {
  height: 0;
  margin-bottom: 1rem;
  overflow: hidden;
  padding-bottom: 66%;
  position: relative; }
  .categories-cta__image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .categories-cta__image img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.categories-cta .arrow-link {
  font-size: 1.625rem;
  font-weight: 700;
  padding-right: 0; }
  .categories-cta .arrow-link:after {
    opacity: 0;
    right: -3rem;
    transition: .25s; }
  .categories-cta .arrow-link:hover, .categories-cta .arrow-link:focus {
    color: #d63a0e; }
    .categories-cta .arrow-link:hover:after, .categories-cta .arrow-link:focus:after {
      opacity: 1;
      transform: translate(0, -50%); }

.categories-cta__item {
  margin-bottom: 3rem; }
  .categories-cta__item h3 {
    margin-bottom: .625rem; }
  .categories-cta__item:hover .arrow-link {
    color: #d63a0e; }
    .categories-cta__item:hover .arrow-link:after {
      opacity: 1;
      transform: translate(0, -50%); }

@media screen and (min-width: 48em) {
  .categories-cta__items {
    margin: 0 -0.75rem;
    margin-bottom: -1.5rem; }
  .categories-cta__item {
    -ms-flex: 1;
    flex: 1;
    margin: 0 0.75rem 1.5rem; }
  .categories-cta .categories-cta__items.six-up {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .categories-cta .categories-cta__items.six-up .categories-cta__item {
      -ms-flex: initial;
      flex: initial;
      width: calc(33.33%  - 1.5rem); } }

.contributors-slider__buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  margin-top: 3rem; }
  .contributors-slider__buttons .flickity-viewport {
    overflow: visible;
    width: 100%; }

.contributors-slider__button {
  background: none;
  border: 4px solid #EEE8E0;
  border-radius: 50%;
  -ms-flex: 1;
  flex: 1;
  height: 5rem;
  margin: 0 .5rem;
  max-width: 5rem;
  padding: 0;
  transition: .25s;
  width: 5rem; }
  .contributors-slider__button.is-selected {
    border-color: rgba(214, 58, 14, 0.3);
    transform: scale(1.25); }

.contributors-slider__image {
  border-radius: 50%;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative; }
  .contributors-slider__image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .contributors-slider__image img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.contributors-slider__text {
  width: 100%; }
  .contributors-slider__text p {
    margin: 0 auto 1.5rem;
    max-width: 40rem; }
  .contributors-slider__text a {
    text-decoration: none; }
    .contributors-slider__text a:hover, .contributors-slider__text a:focus {
      text-decoration: underline; }

.contributors-slider .flickity-enabled,
.contributors-slider .contributors-slider__button {
  box-shadow: none !important; }

.contributors-slider .flickity-viewport {
  transition: .25s; }

@media screen and (min-width: 48em) {
  .contributors-slider__button {
    height: 7rem;
    max-width: 7rem;
    width: 7rem; } }

.featured-articles__image {
  margin-bottom: 1rem;
  padding-bottom: 70%;
  position: relative; }
  .featured-articles__image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .featured-articles__image img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.featured-articles__articles {
  margin-bottom: -1.5rem; }

.featured-articles__article {
  margin-bottom: 1.5rem; }

@media screen and (min-width: 48em) {
  .featured-articles__article {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    width: calc(33.33% - 3rem); }
  .featured-articles__articles {
    margin-left: -0.75rem;
    margin-right: -0.75rem; } }

.featured-articles__comments {
  background-image: url("/wp-content/themes/mdk-2022/library/icons/src/comment-white.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 50%;
  color: #ffffff;
  font-family: "Brandon Grotesque", "Verdana", "Helvetica", sans-serif;
  padding-left: 1.5rem;
  position: absolute;
  top: .5rem;
  left: .5rem;
  z-index: 1; }

.featured-articles.pattern-page h2 {
  color: #d63a0e !important;
  font-size: 2.09715rem !important;
  text-align: start; }
  @media screen and (min-width: 62em) {
    .featured-articles.pattern-page h2 {
      font-size: 2.68435rem !important; } }

.featured-articles.pattern-page .featured-articles__article {
  height: -webkit-fit-content;
  height: fit-content;
  text-align: center; }
  .featured-articles.pattern-page .featured-articles__article a:active,
  .featured-articles.pattern-page .featured-articles__article a:focus,
  .featured-articles.pattern-page .featured-articles__article a:hover {
    text-decoration: underline; }
  .featured-articles.pattern-page .featured-articles__article .related-posts__description {
    margin-bottom: 1.5rem !important; }
  .featured-articles.pattern-page .featured-articles__article:hover {
    background: #F4F0EA; }
  @media screen and (min-width: 48em) {
    .featured-articles.pattern-page .featured-articles__article {
      margin-left: 0.75rem;
      margin-right: 0.75rem; } }

.featured-articles.pattern-page .featured-articles__articles {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.featured-articles.pattern-page .featured-articles__image {
  margin-bottom: 0 !important;
  position: relative; }
  .featured-articles.pattern-page .featured-articles__image .featured-articles__image-tag {
    background: #555;
    color: #ffffff;
    font-family: "Brandon Grotesque", "Verdana", "Helvetica", sans-serif;
    font-size: .8rem;
    letter-spacing: 1px;
    padding: .25rem .5rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    text-transform: uppercase;
    transform: translateX(-50%); }

.featured-articles.pattern-page .featured-articles__meta {
  padding: 0.75rem; }

@media screen and (min-width: 48em) {
  .featured-articles.pattern-page .contain {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important; }
  .featured-articles.pattern-page .featured-articles__article {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    width: calc(33.33% - 1.5rem) !important; }
    .featured-articles.pattern-page .featured-articles__article .featured-articles__image {
      padding-bottom: 100% !important; } }

@media screen and (min-width: 62em) {
  .featured-articles.pattern-page .featured-articles__article {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    width: calc(33.33% - 3rem) !important; }
  .featured-articles.pattern-page .featured-articles__articles {
    margin-left: -1.5rem;
    margin-right: -1.5rem; } }

.full-width-cta__text {
  padding: 1.5rem;
  text-align: center; }

.full-width-cta__image {
  height: 0;
  overflow: hidden;
  padding-bottom: 66%;
  position: relative; }
  .full-width-cta__image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .full-width-cta__image img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

@media screen and (min-width: 48em) {
  .full-width-cta__text {
    padding: 3rem 1.5rem;
    text-align: left;
    width: 40%; }
  .full-width-cta__image {
    padding-bottom: 40%;
    width: 60%; } }

@media screen and (min-width: 62em) {
  .full-width-cta__text {
    width: 30%; }
  .full-width-cta__image {
    width: 70%; } }

/**
 * Two Columns Flex Layout (w/ Icon Row)
 * DBS Interactive
 */
.two-columns {
  position: relative; }
  .two-columns__left, .two-columns__right {
    position: relative; }
    .two-columns__left img, .two-columns__right img {
      display: block; }
  @media screen and (max-width: 47.999em) {
    .two-columns__left[class*="bg"], .two-columns__right[class*="bg"] {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      padding: 1.5rem; }
    .two-columns__left {
      margin-bottom: 1.5rem; } }
  @media screen and (min-width: 48em) {
    .two-columns__left, .two-columns__right {
      width: 50%; }
      .two-columns__left.contain-img img, .two-columns__right.contain-img img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .two-columns__left.contain-img img, .two-columns__right.contain-img img {
            height: 100%;
            object-fit: contain;
            object-position: center; } }
      .two-columns__left.cover, .two-columns__right.cover {
        -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch; }
        .two-columns__left.cover img, .two-columns__right.cover img {
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%; }
          @supports (object-fit: contain) {
            .two-columns__left.cover img, .two-columns__right.cover img {
              height: 100%;
              object-fit: cover;
              object-position: center; } }
      .two-columns__left:not(.has-img), .two-columns__right:not(.has-img) {
        padding: 1.5rem; }
      .two-columns__left.overflow, .two-columns__right.overflow {
        height: 0;
        overflow: hidden;
        padding-bottom: 50%;
        position: static; }
        .two-columns__left.overflow img, .two-columns__right.overflow img {
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          top: 0;
          transform: none;
          width: 50%; }
          @supports (object-fit: contain) {
            .two-columns__left.overflow img, .two-columns__right.overflow img {
              height: 100%;
              object-fit: cover;
              object-position: center; } }
    .two-columns__left.overflow img {
      left: 0; }
    .two-columns__right.overflow img {
      right: 0; } }
  @media screen and (min-width: 62em) {
    .two-columns__left:not(.has-img), .two-columns__right:not(.has-img) {
      padding: 3rem; }
    .two-columns.two-thirds-left .two-columns__left {
      width: 60%; }
    .two-columns.two-thirds-left .two-columns__right {
      width: 40%; }
    .two-columns.two-thirds-right .two-columns__left {
      width: 40%; }
    .two-columns.two-thirds-right .two-columns__right {
      width: 60%; } }
  @media screen and (min-width: 75em) {
    .two-columns__left.has-icons {
      padding-right: 3rem; }
    .two-columns__right.has-icons {
      padding-left: 3rem; } }

.quote .contain {
  max-width: calc(80rem - 3rem);
  position: relative; }
  .quote .contain::before, .quote .contain::after {
    height: 100%;
    width: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0; }
  .quote .contain::before {
    background: url("/wp-content/themes/mdk-2022/library/icons/src/quote-top.svg") no-repeat right top;
    background-size: 20%; }
  .quote .contain::after {
    background: url("/wp-content/themes/mdk-2022/library/icons/src/quote-bottom.svg") no-repeat left bottom;
    background-size: 25%; }

.quote__text {
  margin: 0 auto;
  max-width: 62rem;
  padding: 3rem 0; }

@media screen and (min-width: 48em) {
  .quote .contain::before {
    background-size: 10%; }
  .quote .contain::after {
    background-size: 13%; }
  .quote__text {
    padding: 4.5rem 3rem; } }

/**
 * DBS Chevron Styles
 * DBS>Interactive
 */
#dbs-chev {
  position: relative; }
  #dbs-chev svg {
    height: 2rem;
    max-width: 1rem; }
  #dbs-chev strong {
    font-size: 0.78125em;
    float: right;
    font-weight: normal;
    text-align: center;
    width: 15em; }
    @media screen and (min-width: 48em) {
      #dbs-chev strong {
        width: 21em; } }
  #dbs-chev .text {
    background: #000000;
    max-width: 0;
    overflow: hidden;
    padding: .25em 0;
    position: absolute;
    right: 2em;
    top: 1px;
    transition: all .5s ease-out; }
  #dbs-chev.hover span, #dbs-chev:hover span, #dbs-chev:focus span {
    max-width: 23em; }

/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */
.site-footer {
  overflow: hidden;
  clear: both;
  margin-top: auto; }
  .site-footer .contain {
    padding: 0 1.5rem; }
  .site-footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .site-footer a {
    text-decoration: none; }
    .site-footer a:not(.button):hover {
      text-decoration: underline; }
  .site-footer .site-footer__logo img {
    display: block;
    max-width: 10em;
    padding-bottom: 1em; }
  .site-footer__main {
    padding: 3rem 0; }
  .site-footer__business ul, .site-footer__business li {
    display: block; }
  .site-footer__navigation .footer-menu__item a {
    color: #555;
    display: inline-block;
    font-family: "Brandon Grotesque", "Verdana", "Helvetica", sans-serif;
    font-weight: 500;
    padding: .25rem 0; }
    .site-footer__navigation .footer-menu__item a:after {
      background: #555;
      content: '';
      display: block;
      height: 1px;
      position: relative;
      top: 3px;
      transform: scaleX(0);
      transform-origin: 100% 0;
      transition: transform .25s ease-in-out; }
    .site-footer__navigation .footer-menu__item a:hover, .site-footer__navigation .footer-menu__item a:focus {
      text-decoration: none; }
      .site-footer__navigation .footer-menu__item a:hover:after, .site-footer__navigation .footer-menu__item a:focus:after {
        transform: scaleX(1);
        transform-origin: 0 100%; }
  .site-footer__navigation .footer-menu__item.toplevel {
    margin-bottom: 1rem; }
  .site-footer__navigation li {
    display: block; }
  .site-footer .social-media {
    margin: 1em 0; }
    .site-footer .social-media__link {
      display: inline-block; }
    .site-footer .social-media a {
      display: block;
      width: 2em; }
    .site-footer .social-media svg path {
      fill: #555;
      transition: .25s; }
    .site-footer .social-media a:hover svg path,
    .site-footer .social-media a:focus svg path {
      fill: #d63a0e; }
  .site-footer__credits {
    width: 100%; }
    .site-footer__credits .contain {
      border-top: 2px solid #D2C7B9;
      padding: 1.5rem;
      width: 100%; }
    .site-footer__credits .legal-footer {
      display: inline-block; }
      .site-footer__credits .legal-footer ul, .site-footer__credits .legal-footer li {
        display: inline-block; }
      .site-footer__credits .legal-footer .legal-menu__item {
        border-left: 1px solid #ffffff;
        margin-left: .5rem;
        padding-left: .75rem; }
  @media screen and (max-width: 47.999em) {
    .site-footer__contact {
      border-bottom: 2px solid #D2C7B9;
      border-top: 2px solid #D2C7B9;
      margin: 1.5rem 0 3rem;
      padding: 3rem 0; }
    .site-footer__navigation .footer-menu__submenu {
      display: none; }
    .site-footer__navigation .footer-menu__item--parent {
      position: relative; }
      .site-footer__navigation .footer-menu__item--parent.open .footer-menu__submenu {
        display: block; }
    .site-footer__navigation .submenu-toggle {
      top: -.75rem; }
      .site-footer__navigation .submenu-toggle svg path {
        fill: #ffffff; } }
  @media screen and (min-width: 48em) {
    .site-footer__main {
      padding: 6rem 0; }
    .site-footer__navigation {
      width: 30%; }
      .site-footer__navigation > ul {
        margin: 1.5rem 0; }
    .site-footer__contact {
      border-left: 2px solid #D2C7B9;
      border-right: 2px solid #D2C7B9;
      margin: 0 1.5rem;
      padding: 0 1.5rem;
      width: 40%; }
    .site-footer__meta {
      width: 30%; } }

/**
 * Form Styles
 * DBS>Interactive
 */
/**
 * GravityForms Resets
 */
.validation_error {
  color: #003B36;
  border-bottom: 0.1em solid #003B36;
  padding-bottom: 1em; }

.gform_body ul {
  list-style: none;
  padding: 0; }

.gform_body .gfield_label,
.gform_body label, .gform_body legend {
  font-family: "Brandon Grotesque", "Verdana", "Helvetica", sans-serif;
  font-weight: bold; }

.gform_body .gform-body {
  -ms-flex: 1;
  flex: 1; }

.gfield {
  margin-bottom: 1em; }

.gfield_list_icons {
  padding: .5em; }

.gform_validation_container {
  display: none !important; }

.gf_progressbar_wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between; }

/**
 * Add-clear plugin resets
 */
.add-clear-span {
  display: block;
  position: relative; }
  .add-clear-span > .add-clear-button {
    display: none !important;
    top: .5em;
    right: .5em; }

fieldset > label.gfield_label_before_complex,
fieldset legend.gfield_label_before_complex,
.hidden_label > label.gfield_label_before_complex,
.hidden_label legend.gfield_label_before_complex,
.hide-label > label.gfield_label_before_complex,
.hide-label legend.gfield_label_before_complex {
  display: none; }

fieldset > label, fieldset legend,
.hidden_label > label,
.hidden_label legend,
.hide-label > label,
.hide-label legend {
  height: 1px;
  width: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute; }

/**
 * Input Field Styles
 */
textarea {
  min-height: 5em; }

input[type=radio] {
  margin-right: .5em; }

select {
  min-width: 25%;
  margin: 0; }

textarea,
input[type=text]:not([id=search-input]),
input[type=email],
input[type=date],
input[type=number],
input[type=time],
input[type=week],
input[type=month],
input[type=tel],
input[type=search],
input[type=url],
.gfield select {
  display: block;
  border: 1px solid #D2C7B9;
  margin: .333em 0;
  max-width: 100%;
  padding: .666em;
  width: 100%; }

fieldset {
  border: none;
  margin-bottom: 3rem;
  padding: 0 !important; }

.gform_footer .gform_button.button {
  padding-right: 2.5rem; }
  .gform_footer .gform_button.button::before, .gform_footer .gform_button.button::after {
    content: none; }

/**
 * Complex field arrangements
 */
.ginput_complex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  @media screen and (min-width: 48em) {
    .ginput_complex {
      -ms-flex-direction: row;
      flex-direction: row; }
      .ginput_complex span {
        display: inline-block;
        width: 50%; }
        .ginput_complex span:nth-of-type(2n) {
          margin-left: 1em; } }

/**
 * Remove IE's clear so we don't conflict with the add-clear button functionality 2017-09-12
 */
input[type=text]::-ms-clear {
  display: none; }

/**
 * Validation and error message styling
 */
.gfield_error label,
.gfield_error legend,
.validation_message {
  color: red; }

.gfield_error input,
.gfield_error textarea,
.gfield_error select {
  border-color: red; }

/**
 * Loader animation
 */
.gform_ajax_spinner {
  height: 1em;
  width: 1em;
  animation: spinner_animation 1s infinite ease;
  border-radius: 50%;
  font-size: 5px;
  margin: 0 5em;
  transform: translateZ(0); }

.site-footer__subscribe,
.sidebar__subscribe {
  padding: 3rem 0; }
  .site-footer__subscribe img,
  .sidebar__subscribe img {
    display: block;
    margin: 1rem auto 1.5rem;
    max-width: 9rem; }
  .site-footer__subscribe form,
  .sidebar__subscribe form {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    position: relative; }
    .site-footer__subscribe form .gform_body .gfield:first-child,
    .sidebar__subscribe form .gform_body .gfield:first-child {
      position: relative; }
      .site-footer__subscribe form .gform_body .gfield:first-child::before,
      .sidebar__subscribe form .gform_body .gfield:first-child::before {
        height: 1rem;
        width: 1rem;
        background: url("/wp-content/themes/mdk-2022/library/icons/src/mail.svg") no-repeat center;
        background-size: contain;
        content: '';
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%); }
    .site-footer__subscribe form .gfield,
    .sidebar__subscribe form .gfield {
      margin: 0; }
    .site-footer__subscribe form input[type="email"],
    .sidebar__subscribe form input[type="email"] {
      background: #FDFAF5;
      border: none;
      height: 3rem;
      margin: 0;
      padding: 0 .75rem 0 3rem;
      width: 100%; }
      .site-footer__subscribe form input[type="email"]:-ms-input-placeholder,
      .sidebar__subscribe form input[type="email"]:-ms-input-placeholder {
        color: #838383; }
      .site-footer__subscribe form input[type="email"]::placeholder,
      .sidebar__subscribe form input[type="email"]::placeholder {
        color: #838383; }
    .site-footer__subscribe form input[type="submit"],
    .sidebar__subscribe form input[type="submit"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #d63a0e;
      background-image: url("/wp-content/themes/mdk-2022/library/icons/src/arrow-right-white.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 1.25rem;
      border: 1px solid #d63a0e;
      border-radius: 0;
      color: transparent;
      font-weight: 500;
      height: 3rem;
      padding: 0;
      transition: .25s;
      width: 3rem; }
      .site-footer__subscribe form input[type="submit"]:hover, .site-footer__subscribe form input[type="submit"]:focus, .site-footer__subscribe form input[type="submit"]:active,
      .sidebar__subscribe form input[type="submit"]:hover,
      .sidebar__subscribe form input[type="submit"]:focus,
      .sidebar__subscribe form input[type="submit"]:active {
        background-color: #be340c; }
    .site-footer__subscribe form label,
    .sidebar__subscribe form label {
      height: 1px;
      width: 1px;
      border: 0;
      clip: rect(0 0 0 0);
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      background: transparent;
      color: #000000; }
    .site-footer__subscribe form .gfield_checkbox label,
    .sidebar__subscribe form .gfield_checkbox label {
      height: auto;
      padding-left: 1rem;
      position: static;
      white-space: pre-wrap;
      width: auto; }
    .site-footer__subscribe form .gfield_checkbox .gchoice,
    .sidebar__subscribe form .gfield_checkbox .gchoice {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 0.75rem; }

.sidebar__subscribe {
  background: #F4F0EA;
  margin-bottom: 3rem;
  padding: 1rem 1.5rem; }

.facetwp-facet-pattern_sort {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-family: "Brandon Grotesque", "Verdana", "Helvetica", sans-serif; }
  .facetwp-facet-pattern_sort select {
    background-color: transparent;
    border: 0;
    min-width: auto; }

@keyframes spinner_animation {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.5), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.7), 1.8em -1.8em 0 0em #000000, 2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.5), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7), 2.5em 0em 0 0em #000000, 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5), 2.5em 0em 0 0em rgba(0, 0, 0, 0.7), 1.75em 1.75em 0 0em #000000, 0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.5), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.7), 0em 2.5em 0 0em #000000, -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.5), 0em 2.5em 0 0em rgba(0, 0, 0, 0.7), -1.8em 1.8em 0 0em #000000, -2.6em 0em 0 0em rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.5), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.7), -2.6em 0em 0 0em #000000, -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.5), -2.6em 0em 0 0em rgba(0, 0, 0, 0.7), -1.8em -1.8em 0 0em #000000; } }

/**
 * Animated Form Label
 *
 * Class must be added in the GForms admin
 */
.animate-label {
  position: relative; }
  .animate-label .ginput_complex > span {
    height: 100%;
    width: 100%;
    display: inline-block;
    position: relative; }
    .gform_validation_error .animate-label label, .gform_validation_error .animate-label legend, .animate-label .ginput_complex > span.active label, .animate-label .ginput_complex > span.active legend {
      transform: translateY(-2.25rem) scale(0.75); }
  .animate-label .ginput_complex.gfield_error label {
    transform: translateY(-2.25rem) scale(0.75); }
  .animate-label label, .animate-label legend {
    background: white;
    padding: 0 .25rem;
    position: absolute;
    top: 50%;
    left: .5rem;
    transition: .2s ease-in-out;
    transform: translateY(-50%);
    transform-origin: left; }
  .animate-label.select-field select {
    font-weight: 700;
    padding: 1.5rem .75rem; }
  .animate-label.text-area label {
    top: .5rem;
    transform: none; }
  .animate-label.text-area textarea {
    max-height: 12rem;
    padding-top: 3rem; }
  .animate-label.text-area.gfield_error label, .animate-label.text-area.gfield_error legend {
    transform: none; }
  .gform_validation_error .animate-label label, .gform_validation_error .animate-label legend, .animate-label.active label, .animate-label.active legend {
    transform: translateY(-2.25rem) scale(0.75); }

/**
 * DBS Slate Stylesheet
 * DBS>Interactive
 */
