.quote {

	.contain {
		max-width: calc(#{$containSize} - #{$spacing*2});
		position: relative;

		&::before,
		&::after {
			@include size(100%);
			content: '';
			position: absolute;
				top: 0;
				left: 0;
		}

		&::before {
			background: url('#{$libraryPath}/icons/src/quote-top.svg') no-repeat right top;
				background-size: 20%;
		}

		&::after {
			background: url('#{$libraryPath}/icons/src/quote-bottom.svg') no-repeat left bottom;
				background-size: 25%;
		}
	}

	&__text {
		margin: 0 auto;
		max-width: 62rem;
		padding: #{$spacing*2} 0;
	}

	@include media($screen-sm) {

		.contain {
			&::before {background-size: 10%;}
			&::after {background-size: 13%;}
		}
		
		&__text {
			padding: #{$spacing*3} #{$spacing*2};
		}
	}
}