.categories-cta {

	&__image {
		height: 0;
		margin-bottom: 1rem;
		overflow: hidden;
		padding-bottom: 66%;
		position: relative;
		
		img {
			@include object-fit(cover, center);
		}
	}

	.arrow-link {
        font-size: 1.625rem;
        font-weight: 700;
		padding-right: 0;

		&:after {
			opacity: 0;
			right: -#{$spacing*2};
			transition: .25s;
		}

		&:hover,
		&:focus {
			color: $orange;

			&:after {
				opacity: 1;
				transform: translate(0, -50%);
			}
		}
	}

	&__item {
		margin-bottom: #{$spacing * 2};
        
        h3 {
            margin-bottom: .625rem;
        }

		&:hover {
			.arrow-link {
				color: $orange;

				&:after {
					opacity: 1;
					transform: translate(0, -50%);
				}
			}
		}
	}

	@include media($screen-sm) {
		&__items {
			margin: 0 -#{$spacing/2};
			margin-bottom: -$spacing;
		}

		&__item {
			flex: 1;
			margin: 0 #{$spacing/2} $spacing;
		}

		.categories-cta__items.six-up {
			flex-wrap: wrap;

			.categories-cta__item {
				flex: initial;
				width: calc(33.33%  - 1.5rem);
			}
		}
	}
}