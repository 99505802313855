.full-width-cta {

	&__text {
		padding: $spacing;
		text-align: center;
	}

	&__image {
		height: 0;
		overflow: hidden;
		padding-bottom: 66%;
		position: relative;

		img {@include object-fit(cover, center);}
	}

	@include media ($screen-sm) {

		&__text {
			padding: #{$spacing*2} #{$spacing};
			text-align: left;
			width: 40%;
		}

		&__image {
			padding-bottom: 40%;
			width: 60%;
		}
	}

	@include media ($screen-md) {

		&__text {width: 30%;}

		&__image {width: 70%;}
	}
}