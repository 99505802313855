.featured-articles {
	&__image {
		margin-bottom: 1rem;
		padding-bottom: 70%;
		position: relative;

		img {@include object-fit(cover, center);}
	}

    &__articles {margin-bottom: -$spacing;}

    &__article {margin-bottom: $spacing;}
	
	@include media($screen-sm) {

		&__article {
            margin-left: $spacing;
            margin-right: $spacing;
			width: calc(33.33% - #{$spacing*2});
		}

        &__articles {
            margin-left: -$spacing/2;
            margin-right: -$spacing/2;
        }
	}
    
    &__comments {
        background-image: url('#{$libraryPath}/icons/src/comment-white.svg');
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 50%;
        color: $white;
        font-family: $font-brandon-grotesque;
        padding-left: 1.5rem;
        position: absolute;
            top: .5rem;
            left: .5rem;
        z-index: 1;
    }
    
    &.pattern-page {
        h2 {
            color: $orange !important;
            font-size: 2.09715rem !important;
            text-align: start;
            
            @include media($screen-md) {
                font-size: 2.68435rem !important;
            }
        }
        
        .featured-articles__article {
            height: fit-content;
            text-align: center;
            
            a:active,
            a:focus,
            a:hover {
                text-decoration: underline;
            }
            
            .related-posts__description {
                margin-bottom: $spacing !important;
            }
            
            &:hover {
                background: $tanLighter;
            }
            
            @include media($screen-sm) {
                margin-left: #{$spacing/2};
                margin-right: #{$spacing/2};
            }
        }
        
        .featured-articles__articles {
            justify-content: flex-start !important;
        }
        
        .featured-articles__image {
            margin-bottom: 0 !important;
            position: relative;
            
            .featured-articles__image-tag {
                background: $charcoal;
                color: $white;
                font-family: $font-brandon-grotesque;
                font-size: .8rem;
                letter-spacing: 1px;
                padding: .25rem .5rem;
                position: absolute;
                    bottom: 0;
                    left: 50%;
                text-transform: uppercase;
                transform: translateX(-50%);
            }
        }
        
        .featured-articles__meta {
            padding: #{$spacing / 2};
        }
        
        @include media($screen-sm) {
            .contain {
                padding-left: #{$spacing * 3} !important;
                padding-right: #{$spacing * 3} !important;
            }
            
            .featured-articles__article {
                margin-left: #{$spacing/2};
                margin-right: #{$spacing/2};
                width: calc(33.33% - #{$spacing}) !important;
                
                .featured-articles__image {
                    padding-bottom: 100% !important;
                }
            }
        }

        @include media($screen-md) {
            .featured-articles__article {
                margin-left: #{$spacing};
                margin-right: #{$spacing};
                width: calc(33.33% - #{$spacing * 2}) !important;
            }

            .featured-articles__articles {
                margin-left: -$spacing;
                margin-right: -$spacing;
            }
        }
    }
}